import React, { useState } from 'react'
import { AiFillEye } from 'react-icons/ai'
import { motion } from 'framer-motion'

import { appWrap } from '../../wrapper'
import './Work.scss'
import { images } from '../../constants'

const works = [
  {
    title: 'Chat APP',
    description:
      'Instant messaging,type of online chat allowing real-time text transmission over the Internet(just for PC).',
    projectLink: 'https://nabil-chat-app-v3.herokuapp.com/',

    imgUrl: images.chatApp,
    tags: 'NODE',
  },
  {
    title: "Travel's World",
    description:
      'A Web App that allows you to publish the places you loved during your travels,and see others for people.',
    projectLink: 'https://nwposts.netlify.app/posts',

    imgUrl: images.posts,
    tags: ' MERN',
  },
  {
    title: 'Simple Movie App',
    description:
      'A Simple  Web App that allows you to search the movies you loved using the tmdb api.',
    projectLink: 'https://nwmovie.netlify.app',

    imgUrl: images.movie,
    tags: ' REACT',
  },
  {
    title: 'Modern UI/UX Landing page',
    description:
      'Template Landing page  using Scss and modern UI/UX gradients colors.',
    projectLink: 'https://naoc.netlify.app/',

    imgUrl: images.lp,
    tags: ' REACT',
  },
  {
    title: 'Transactions app',
    description:
      'Lets put our transactions under controle, sign up and add your activities.',
    projectLink: 'https://trnst.netlify.app/login',

    imgUrl: images.expensify,

    tags: 'MERN',
  },
]

const Work = () => {
  const [filterWork, setFilterWork] = useState(works)
  const [activeFilter, setActiveFilter] = useState('All')
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 })

  // useEffect(() => {
  //   const query = '*[_type == "works"]'

  //   client.fetch(query).then((data) => {
  //     setWorks(data)
  //     setFilterWork(data)
  //   })
  // }, [])

  const handleWorkFilter = (item) => {
    setActiveFilter(item)
    setAnimateCard([{ y: 100, opacity: 0 }])

    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }])

      if (item === 'All') {
        setFilterWork(works)
      } else {
        setFilterWork(works.filter((work) => work.tags.includes(item)))
      }
    }, 500)
  }

  return (
    <>
      <h2 className='head-text'>
        <span>Projects</span>
      </h2>

      <div className='app__work-filter'>
        {['MERN', 'REACT', 'NODE', 'All'].map((item, index) => (
          <div
            key={index}
            onClick={() => handleWorkFilter(item)}
            className={`app__work-filter-item app__flex p-text ${
              activeFilter === item ? 'item-active' : ''
            }`}
          >
            {item}
          </div>
        ))}
      </div>

      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className='app__work-portfolio'
      >
        {filterWork.map((work, index) => (
          <div className='app__work-item app__flex' key={index}>
            <div className='app__work-img app__flex'>
              <img src={work.imgUrl} alt={work.name} />

              <motion.div
                whileHover={{ opacity: [0, 1] }}
                transition={{
                  duration: 0.25,
                  ease: 'easeInOut',
                  staggerChildren: 0.5,
                }}
                className='app__work-hover app__flex'
              >
                <a href={work.projectLink} target='_blank' rel='noreferrer'>
                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    whileHover={{ scale: [1, 0.9] }}
                    transition={{ duration: 0.25 }}
                    className='app__flex'
                  >
                    <AiFillEye />
                  </motion.div>
                </a>
              </motion.div>
            </div>

            <div className='app__work-content app__flex'>
              <h4 className='bold-text'>{work.title}</h4>
              <p className='p-text' style={{ marginTop: 10 }}>
                {work.description}
              </p>

              <div className='app__work-tag app__flex'>
                <p className='p-text'>{work.tags}</p>
              </div>
            </div>
          </div>
        ))}
      </motion.div>
    </>
  )
}

export default appWrap(Work, 'projects')
