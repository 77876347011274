import email from '../assets/email.png'
import w from '../assets/w.png'

import css from '../assets/css.png'

import git from '../assets/git.png'
import graphql from '../assets/graphql.png'
import html from '../assets/html.png'
import javascript from '../assets/javascript.png'
import node from '../assets/node.png'
import react from '../assets/react.png'
import redux from '../assets/redux.png'
import sass from '../assets/sass.png'

import about02 from '../assets/about02.png'
import about04 from '../assets/about04.png'

import profile from '../assets/pB.png'
// import profile from '../assets/profile.png'
import circle from '../assets/circle.svg'

import back from '../assets/back.jpg'

import expensify from '../assets/expensify.png'
import chatApp from '../assets/chatApp.png'
import posts from '../assets/posts.png'
import mongodb from '../assets/MongoDB.jpg'
import webdev from '../assets/webdev.jpg'
import movie from '../assets/movie.png'
import lp from '../assets/lp.png'
export default {
  lp,

  movie,
  webdev,
  mongodb,
  posts,
  chatApp,
  expensify,
  email,
  w,
  back,

  css,

  git,
  graphql,
  html,
  javascript,

  node,

  react,
  redux,
  sass,

  about02,
  about04,
  profile,
  circle,
}
