import React from 'react'
import { BsTwitter, BsInstagram, BsLinkedin, BsGithub } from 'react-icons/bs'
import { FaFacebookF } from 'react-icons/fa'

const SocialMedia = () => (
  <div className='app__social'>
    <a
      href='https://www.linkedin.com/in/nabil-wazze-7939ab212/'
      target='_blank'
    >
      {' '}
      <div>
        <BsLinkedin />
      </div>
    </a>
    <a href='https://github.com/Nabilwz' target='_blank'>
      {' '}
      <div>
        <BsGithub />
      </div>
    </a>
    <a href='https://twitter.com/NabilWazze'>
      <div>
        <BsTwitter />
      </div>
    </a>

    <a href='  https://www.facebook.com/nabilWz2001' target='_blank'>
      <div>
        <FaFacebookF />
      </div>
    </a>
    <a href='https://www.instagram.com/nabil_wazze/' target='_blank'>
      {' '}
      <div>
        <BsInstagram />
      </div>
    </a>
  </div>
)

export default SocialMedia
