import React, { useState } from 'react'

import { images } from '../../constants'
import { appWrap } from '../../wrapper'
import emailjs from '@emailjs/browser'
import './Footer.scss'

const Footer = () => {
  const [Name, setName] = useState('')
  const [email, setEmail] = useState('')

  const [message, setMessage] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    emailjs
      .sendForm(
        'gmail',
        'template_jr9p8jp',
        e.target,
        'user_P9acogMscGFUbvGFn1Ltp'
      )
      .then(
        (result) => {
          console.log(result.text)
        },
        (error) => {
          console.log(error.text)
        }
      )
    setName('')
    setEmail('')

    setMessage('')
  }

  return (
    <>
      <h2 className='head-text'>Don't hesitate to drop me a message</h2>
      <div className='app__footer-cards'>
        <div className='app__footer-card '>
          <img src={images.email} alt='email' />
          <a href='mailto: n.wazze@zendy.io' className='p-text'>
            n.wazze@zendy.io
          </a>
        </div>
        <div className='app__footer-card '>
          <img src={images.email} alt='email' />
          <a href='mailto:  nabilwazze19@gmail.com' className='p-text'>
            nabilwazze19@gmail.com
          </a>
        </div>
      </div>
      <>
        <form onSubmit={handleSubmit} className='app__footer-form app__flex'>
          <div className='app__flex'>
            <input
              id='name'
              className='p-text'
              type='text'
              placeholder='Your Name'
              name='Name'
              value={Name}
              required
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className='app__flex'>
            <input
              className='p-text'
              type='email'
              id='email'
              placeholder='X@Y.com'
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
              name='Email'
            />
          </div>
          <div>
            <textarea
              className='p-text'
              type='text'
              id='message'
              placeholder='Start typing your message...'
              value={message}
              required
              onChange={(e) => setMessage(e.target.value)}
              name='Message'
            />
          </div>
          <button type='submit' className='p-text'>
            Send
          </button>
        </form>
      </>
    </>
  )
}
export default appWrap(Footer, 'contact')
