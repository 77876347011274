import React from 'react'
import { motion } from 'framer-motion'

import { appWrap } from '../../wrapper'
import { images } from '../../constants'
import './Header.scss'

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
}

const Header = () => (
  <div className='app__header app__flex'>
    <motion.div
      whileInView={{ opacity: [0, 1] }}
      transition={{ duration: 0.5, delayChildren: 0.5 }}
      className='app__header-left'
    >
      <h4 className='app__header-left-hi'>Hi, my name is</h4>
      <span className='app__header-left-hi-line'>
        <hr />
      </span>

      <h1 className='app__header-left-name'>Nabil Wazze</h1>
      <h4 className='app__header-left-webDeveloper'>Web Developer</h4>
      <p className='app__header-left-text'>
        specializing in MERN development. currently working as Full Stack
        developer at{' '}
        <a href='https://zendy.io/' target='_blank'>
          Zendy
        </a>
        .
      </p>
    </motion.div>
    <motion.div
      whileInView={{ opacity: [0, 1] }}
      transition={{ duration: 0.5, delayChildren: 0.5 }}
      className='app__header-img'
    >
      <img src={images.profile} alt='profile_bg' />
    </motion.div>
  </div>
)

export default appWrap(Header, 'home')
