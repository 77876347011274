import React from 'react'
import { motion } from 'framer-motion'
import ReactTooltip from 'react-tooltip'

import { appWrap } from '../../wrapper'

import { images } from '../../constants'
import './Skills.scss'

const skills = [
  {
    name: 'Reactjs',
    bgColor: 'this is desc',
    icon: images.react,
  },
  {
    name: 'Nodejs',
    bgColor: 'this is desc',
    icon: images.node,
  },
  {
    name: 'Git',
    bgColor: 'this is desc',
    icon: images.git,
  },
  {
    name: 'Redux',
    bgColor: 'this is desc',
    icon: images.redux,
  },
  {
    name: 'Sass',
    bgColor: 'this is desc',
    icon: images.sass,
  },

  {
    name: 'MongoDB',
    bgColor: 'this is desc',
    icon: images.mongodb,
  },
]

const experiences = [
  {
    year: '2020-Present',
    work: {
      name: 'Freelancer-MERN Stack developer',
      company: 'own projects',
    },
  },
  {
    year: ' July , 2022 - present',
    work: {
      name: 'Full Stack developer',
      company: 'Zendy',
      href: 'https://app-global.zendy.io/',
    },
  },
]

const Skills = () => {
  return (
    <>
      <div className='app__skills-container'>
        {/*  <motion.div className='app__skills-list'>
          {skills.map((skill) => (
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className='app__skills-item app__flex'
              key={skill.name}
            >
              <div
                className='app__flex'
                style={{ backgroundColor: skill.bgColor }}
              >
                <img src={skill.icon} alt={skill.name} />
              </div>
              <p className='p-text'>{skill.name}</p>
            </motion.div>
          ))}
        </motion.div> */}
        <div className='app__skills-exp'>
          {experiences.map((experience) => (
            <motion.div className='app__skills-exp-item' key={experience.year}>
              <div className='app__skills-exp-year'>
                <p className='bold-text'>{experience.year}</p>
              </div>
              <motion.div className='app__skills-exp-works'>
                <>
                  <motion.div
                    whileInView={{ opacity: [0, 1] }}
                    transition={{ duration: 0.5 }}
                    className='app__skills-exp-work'
                    data-tip
                  >
                    <h4 className='bold-text'>{experience.work.name}</h4>
                    <p className='p-text'>
                      {' '}
                      <a href={experience.work.href} target='_blank'>
                        {' '}
                        {experience.work.company}
                      </a>{' '}
                    </p>
                  </motion.div>
                </>
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </>
  )
}

export default appWrap(Skills, 'skills')
